"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const component_1 = require("../packages/component-framework/component");
class Banner extends component_1.Component {
    constructor(element) {
        super(element);
    }
}
exports.default = Banner;
Banner.selector = 'header.banner';
